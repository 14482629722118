/*
 * Copyright 2022 - 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

nav#navBarId:hover {
    background-color: #228b22e3!important;
}

nav#navBarId {
    background-color: transparent!important;
}

#navBarId .navbar-nav .nav-link {
    color: white;
}

#navBarId .navbar-nav .nav-link:hover {
    color: yellow;
}

#navBarId .navbar-nav .nav-link:focus {
    color: yellow;
}
